
















































import { Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "@/util/Storage"
import { UserType } from "@/Api/Basics/InterfaceType"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface ItemType<T=string>{
    icon:T;
    content:T;
    path?:T;
}

interface HandUserType<T=string>{
    defaultIcon:T;
    RightIcon:T;
    BuyList:Array<ItemType>
    List:Array<ItemType>
    UserInfo:UserType

    handleToPathUser():void;
    handleToBuy(data:ItemType,index:number):void;
    handleToList(data:ItemType):void;
}

@Component({ name:"HandUser",components:{ HeadTop } })
export default class HandUser extends ZoomPage implements HandUserType{
    defaultIcon = require("$icon/Basics/default.png");
    RightIcon = require("$icon/Basics/Right.png")
    BuyList = [
        {
            icon:require("$icon/Hand/BuyGo.png"),
            content:"我的卖出"
        },
        {
            icon:require("$icon/Hand/Buy.png"),
            content:"我的卖出"
        }
    ]

    List = [
        {
            icon:require("$icon/Hand/text.png"),
            content:"我的发布",
            path:"/myHand"
        },
        {
            icon:require("$icon/Hand/authent.png"),
            content:"我的认证",
            path:"/authentication"
        },
    ]
    UserInfo:UserType = {}

    mounted() {
       try{
           this.UserInfo = JSON.parse( Stroage.GetData_ && Stroage.GetData_("UserData") )
       }catch(cat){ }
    }

    handleToPathUser(){
        this.$router.push({
            path:"/userInfo"
        })
    }

    handleToBuy(data:ItemType,index:number){
        if( index ){
            this.$router.push({
               path:"/handBuyList",
               query:{
                   title:"我的卖出",
                   buyBool:"true"
               }
           })
        }else{
            this.$router.push({
               path:"/handBuyList",
               query:{
                   title:"我买到的"
               }
           })
        }
    }

    handleToList(data:ItemType){
        if( !data.path?.length ){
            Toast({
               message:"暂无组件",
               icon:"none",
               className:"ToastClassName"
           })
            return
        }
        this.$router.push({
            path:data.path
        })
    }
}
